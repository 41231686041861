import {createContext, useRef, useMemo, useContext} from 'react'

import {BundleName, createFlairSampler} from 'src/lib/flair'

export interface FlairSampler {
  flair: (key?: string) => string | null
  slim: (key?: string) => string | null
  related: () => string | null
}

export const FlairContext = createContext<FlairSampler>({
  flair: () => {
    throw new Error('Missing FlairProvider')
  },
  slim: () => {
    throw new Error('Missing FlairProvider')
  },
  related: () => {
    throw new Error('Missing FlairProvider')
  },
})

interface Props {
  seed: number
  bundleName?: BundleName
  children?: React.ReactNode | React.ReactNode[]
}

const disabledFlairSampler = {
  flair: () => null,
  slim: () => null,
  related: () => null,
}

export const DisableFlairProvider = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[]
}) => (
  <FlairContext.Provider value={disabledFlairSampler}>
    {children}
  </FlairContext.Provider>
)

export const FlairProvider = ({children, bundleName, seed}: Props) => {
  const flairSampler = useMemo(
    () => createFlairSampler(seed, bundleName),
    [seed, bundleName]
  )
  const flairCache = useRef<Record<string, string>>({})
  const slimCache = useRef<Record<string, string>>({})

  const actions = useMemo(
    () => ({
      flair: (key?: string) => {
        const result = key && flairCache.current[key]
        if (result) {
          return result
        }
        const newFlair = flairSampler.flair()
        if (key) {
          flairCache.current[key] = newFlair
        }
        return newFlair
      },
      slim: (key?: string) => {
        const result = key && slimCache.current[key]
        if (result) {
          return result
        }
        const newSlim = flairSampler.slim()
        if (key) {
          slimCache.current[key] = newSlim
        }
        return newSlim
      },
      related: () => flairSampler.related(),
    }),
    [flairSampler]
  )

  return (
    <FlairContext.Provider value={actions}>{children}</FlairContext.Provider>
  )
}

export const useFlairContext = () => useContext(FlairContext)
