import {useWindowSize} from 'react-use'
import {useEffect} from 'react'

export const VH_VAR = '--vh'

const setVh = (height: number) =>
  document.documentElement.style.setProperty(VH_VAR, `${height * 0.01}px`)

export const VerticalHeight = () => {
  const {height} = useWindowSize()

  useEffect(() => {
    setVh(height)
  }, [height])

  return null
}
