import {createContext, useState, useContext, useMemo} from 'react'

export const NavDrawerContext = createContext<{isOpen: boolean}>({
  isOpen: false,
})

export const NavDrawerActionContext = createContext<{
  openDrawer: () => void
  closeDrawer: () => void
  toggleDrawer: () => void
}>({openDrawer: () => {}, closeDrawer: () => {}, toggleDrawer: () => {}})

export const NavDrawerProvider = ({children}: {children?: React.ReactNode}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const actions = useMemo(
    () => ({
      openDrawer: () => setIsOpen(true),
      closeDrawer: () => setIsOpen(false),
      toggleDrawer: () => setIsOpen((state) => !state),
    }),
    []
  )

  return (
    <NavDrawerContext.Provider value={{isOpen}}>
      <NavDrawerActionContext.Provider value={actions}>
        {children}
      </NavDrawerActionContext.Provider>
    </NavDrawerContext.Provider>
  )
}

export const useNavDrawer = () => useContext(NavDrawerContext)
export const useNavDrawerAction = () => useContext(NavDrawerActionContext)
