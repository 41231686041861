import {VH_VAR} from 'src/VerticalHeight'

import {gradients} from './gradients'
import {theme} from './theme'
import * as typography from './typography'
import * as mediaQuery from './mediaQuery'
import * as animation from './animation'
import * as shadow from './shadow'
import * as constants from './constants'
import {zIndexes} from './zIndexes'
import * as borders from './borders'

export const cssVar = (variableName: string, fallback?: string) =>
  `var(${[variableName, fallback].filter(Boolean).join(', ')})`

export const cssCalc = (...expression: Array<string | number>) =>
  `calc(${expression.join(' ')})`

export const vhHeight = (vhCount: number) =>
  cssCalc(cssVar(VH_VAR, '1vh'), '*', vhCount)

export {
  typography,
  theme,
  mediaQuery,
  animation,
  shadow,
  zIndexes,
  constants,
  gradients,
  borders,
}
