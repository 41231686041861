import queryString from 'query-string'

export type Method = 'get' | 'post' | 'put' | 'delete'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Params
  extends Record<
    string,
    string | string[] | boolean | number | undefined | null | Params | Params[]
  > {}

export type RequestOptions = Partial<
  Omit<Request, 'method' | 'url' | 'body' | 'headers'>
> & {
  headers?: Record<string, string>
}

const fetchRequest = async <T>(req: RequestInfo): Promise<T> => {
  const response = await fetch(req)
  if (!response.ok) {
    console.log('Request failed with code: ' + response.status.toString())
  }
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.includes('application/json')) {
    return response.json() as unknown as T
  }
  return undefined as any
}

const buildQueryString = (path: string, params: Params) => {
  const query = queryString.stringify(params)

  return query ? `${path.includes('?') ? '&' : '?'}${query}` : ''
}

const buildUrl = (path: string, params: Params = {}) =>
  [path, buildQueryString(path, params)].join('')

export const buildRequest = (
  method: Method,
  url: string,
  params: Params,
  {headers, ...options}: RequestOptions = {}
): Request => {
  if (method === 'get') {
    return new Request(buildUrl(url, params), {
      method,
      headers: {
        Accept: 'application/json',
        ...headers,
      },
      ...options,
    })
  }

  return new Request(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(params),
    ...options,
  })
}

export const request = async <T>(
  method: Method,
  url: string,
  params: Params = {},
  options?: RequestOptions
): Promise<T> => fetchRequest(buildRequest(method, url, params, options))
