import slim_bundle_01_01 from 'assets/svgs/flair-bundles/slim-bundle-01_01.svg'
import slim_bundle_01_02 from 'assets/svgs/flair-bundles/slim-bundle-01_02.svg'
import slim_bundle_01_03 from 'assets/svgs/flair-bundles/slim-bundle-01_03.svg'
import slim_bundle_01_04 from 'assets/svgs/flair-bundles/slim-bundle-01_04.svg'
import slim_bundle_01_05 from 'assets/svgs/flair-bundles/slim-bundle-01_05.svg'
import slim_bundle_01_06 from 'assets/svgs/flair-bundles/slim-bundle-01_06.svg'
import slim_bundle_01_07 from 'assets/svgs/flair-bundles/slim-bundle-01_07.svg'
import slim_bundle_01_08 from 'assets/svgs/flair-bundles/slim-bundle-01_08.svg'
import slim_bundle_01_09 from 'assets/svgs/flair-bundles/slim-bundle-01_09.svg'
import slim_bundle_01_10 from 'assets/svgs/flair-bundles/slim-bundle-01_10.svg'
import slim_bundle_01_11 from 'assets/svgs/flair-bundles/slim-bundle-01_11.svg'
import slim_bundle_01_12 from 'assets/svgs/flair-bundles/slim-bundle-01_12.svg'
import slim_bundle_01_13 from 'assets/svgs/flair-bundles/slim-bundle-01_13.svg'
import slim_bundle_01_14 from 'assets/svgs/flair-bundles/slim-bundle-01_14.svg'
import slim_bundle_01_15 from 'assets/svgs/flair-bundles/slim-bundle-01_15.svg'
import slim_bundle_01_16 from 'assets/svgs/flair-bundles/slim-bundle-01_16.svg'
import sq_bundle_01_01 from 'assets/svgs/flair-bundles/sq-bundle_01-01.svg'
import sq_bundle_01_02 from 'assets/svgs/flair-bundles/sq-bundle_01-02.svg'
import sq_bundle_01_03 from 'assets/svgs/flair-bundles/sq-bundle_01-03.svg'
import sq_bundle_01_04 from 'assets/svgs/flair-bundles/sq-bundle_01-04.svg'
import sq_bundle_01_squiggle from 'assets/svgs/flair-bundles/sq-bundle_01-squiggle.svg'
import sq_bundle_02_01 from 'assets/svgs/flair-bundles/sq-bundle_02-01.svg'
import sq_bundle_02_02 from 'assets/svgs/flair-bundles/sq-bundle_02-02.svg'
import sq_bundle_02_03 from 'assets/svgs/flair-bundles/sq-bundle_02-03.svg'
import sq_bundle_02_04 from 'assets/svgs/flair-bundles/sq-bundle_02-04.svg'
import sq_bundle_02_squiggle from 'assets/svgs/flair-bundles/sq-bundle_02-squiggle.svg'
import sq_bundle_03_01 from 'assets/svgs/flair-bundles/sq-bundle_03-01.svg'
import sq_bundle_03_02 from 'assets/svgs/flair-bundles/sq-bundle_03-02.svg'
import sq_bundle_03_03 from 'assets/svgs/flair-bundles/sq-bundle_03-03.svg'
import sq_bundle_03_04 from 'assets/svgs/flair-bundles/sq-bundle_03-04.svg'
import sq_bundle_03_squiggle from 'assets/svgs/flair-bundles/sq-bundle_03-squiggle.svg'
import sq_bundle_04_01 from 'assets/svgs/flair-bundles/sq-bundle_04-01.svg'
import sq_bundle_04_02 from 'assets/svgs/flair-bundles/sq-bundle_04-02.svg'
import sq_bundle_04_03 from 'assets/svgs/flair-bundles/sq-bundle_04-03.svg'
import sq_bundle_04_04 from 'assets/svgs/flair-bundles/sq-bundle_04-04.svg'
import sq_bundle_04_squiggle from 'assets/svgs/flair-bundles/sq-bundle_04-squiggle.svg'
import sq_bundle_05_01 from 'assets/svgs/flair-bundles/sq-bundle_05-01.svg'
import sq_bundle_05_02 from 'assets/svgs/flair-bundles/sq-bundle_05-02.svg'
import sq_bundle_05_03 from 'assets/svgs/flair-bundles/sq-bundle_05-03.svg'
import sq_bundle_05_04 from 'assets/svgs/flair-bundles/sq-bundle_05-04.svg'
import sq_bundle_05_squiggle from 'assets/svgs/flair-bundles/sq-bundle_05-squiggle.svg'
import sq_bundle_06_01 from 'assets/svgs/flair-bundles/sq-bundle_06-01.svg'
import sq_bundle_06_02 from 'assets/svgs/flair-bundles/sq-bundle_06-02.svg'
import sq_bundle_06_03 from 'assets/svgs/flair-bundles/sq-bundle_06-03.svg'
import sq_bundle_06_04 from 'assets/svgs/flair-bundles/sq-bundle_06-04.svg'
import sq_bundle_06_squiggle from 'assets/svgs/flair-bundles/sq-bundle_06-squiggle.svg'
import sq_bundle_07_01 from 'assets/svgs/flair-bundles/sq-bundle_07-01.svg'
import sq_bundle_07_02 from 'assets/svgs/flair-bundles/sq-bundle_07-02.svg'
import sq_bundle_07_03 from 'assets/svgs/flair-bundles/sq-bundle_07-03.svg'
import sq_bundle_07_04 from 'assets/svgs/flair-bundles/sq-bundle_07-04.svg'
import sq_bundle_07_squiggle from 'assets/svgs/flair-bundles/sq-bundle_07-squiggle.svg'
import sq_bundle_08_01 from 'assets/svgs/flair-bundles/sq-bundle_08-01.svg'
import sq_bundle_08_02 from 'assets/svgs/flair-bundles/sq-bundle_08-02.svg'
import sq_bundle_08_03 from 'assets/svgs/flair-bundles/sq-bundle_08-03.svg'
import sq_bundle_08_04 from 'assets/svgs/flair-bundles/sq-bundle_08-04.svg'
import sq_bundle_08_squiggle from 'assets/svgs/flair-bundles/sq-bundle_08-squiggle.svg'
import sq_bundle_09_01 from 'assets/svgs/flair-bundles/sq-bundle_09-01.svg'
import sq_bundle_09_02 from 'assets/svgs/flair-bundles/sq-bundle_09-02.svg'
import sq_bundle_09_03 from 'assets/svgs/flair-bundles/sq-bundle_09-03.svg'
import sq_bundle_09_04 from 'assets/svgs/flair-bundles/sq-bundle_09-04.svg'
import sq_bundle_09_squiggle from 'assets/svgs/flair-bundles/sq-bundle_09-squiggle.svg'
import sq_bundle_10_01 from 'assets/svgs/flair-bundles/sq-bundle_10-01.svg'
import sq_bundle_10_02 from 'assets/svgs/flair-bundles/sq-bundle_10-02.svg'
import sq_bundle_10_03 from 'assets/svgs/flair-bundles/sq-bundle_10-03.svg'
import sq_bundle_10_04 from 'assets/svgs/flair-bundles/sq-bundle_10-04.svg'
import sq_bundle_10_squiggle from 'assets/svgs/flair-bundles/sq-bundle_10-squiggle.svg'
import sq_bundle_11_01 from 'assets/svgs/flair-bundles/sq-bundle_11-01.svg'
import sq_bundle_11_02 from 'assets/svgs/flair-bundles/sq-bundle_11-02.svg'
import sq_bundle_11_03 from 'assets/svgs/flair-bundles/sq-bundle_11-03.svg'
import sq_bundle_11_04 from 'assets/svgs/flair-bundles/sq-bundle_11-04.svg'
import sq_bundle_11_squiggle from 'assets/svgs/flair-bundles/sq-bundle_11-squiggle.svg'
import sq_bundle_12_01 from 'assets/svgs/flair-bundles/sq-bundle_12-01.svg'
import sq_bundle_12_02 from 'assets/svgs/flair-bundles/sq-bundle_12-02.svg'
import sq_bundle_12_03 from 'assets/svgs/flair-bundles/sq-bundle_12-03.svg'
import sq_bundle_12_04 from 'assets/svgs/flair-bundles/sq-bundle_12-04.svg'
import sq_bundle_12_squiggle from 'assets/svgs/flair-bundles/sq-bundle_12-squiggle.svg'
import sq_bundle_13_01 from 'assets/svgs/flair-bundles/sq-bundle_13-01.svg'
import sq_bundle_13_02 from 'assets/svgs/flair-bundles/sq-bundle_13-02.svg'
import sq_bundle_13_03 from 'assets/svgs/flair-bundles/sq-bundle_13-03.svg'
import sq_bundle_13_04 from 'assets/svgs/flair-bundles/sq-bundle_13-04.svg'
import sq_bundle_13_squiggle from 'assets/svgs/flair-bundles/sq-bundle_13-squiggle.svg'
import sq_bundle_14_01 from 'assets/svgs/flair-bundles/sq-bundle_14-01.svg'
import sq_bundle_14_02 from 'assets/svgs/flair-bundles/sq-bundle_14-02.svg'
import sq_bundle_14_03 from 'assets/svgs/flair-bundles/sq-bundle_14-03.svg'
import sq_bundle_14_04 from 'assets/svgs/flair-bundles/sq-bundle_14-04.svg'
import sq_bundle_14_squiggle from 'assets/svgs/flair-bundles/sq-bundle_14-squiggle.svg'

export const allSlim = [
  slim_bundle_01_01,
  slim_bundle_01_02,
  slim_bundle_01_03,
  slim_bundle_01_04,
  slim_bundle_01_05,
  slim_bundle_01_06,
  slim_bundle_01_07,
  slim_bundle_01_08,
  slim_bundle_01_09,
  slim_bundle_01_10,
  slim_bundle_01_11,
  slim_bundle_01_12,
  slim_bundle_01_13,
  slim_bundle_01_14,
  slim_bundle_01_15,
  slim_bundle_01_16,
]

export interface FlairBundle {
  flair: string[]
  slim: typeof allSlim
  related: string
}

export const bundles: Record<string, FlairBundle> = {
  bundle1: {
    flair: [sq_bundle_01_01, sq_bundle_01_02, sq_bundle_01_03, sq_bundle_01_04],
    related: sq_bundle_01_squiggle,
    slim: allSlim,
  },

  bundle2: {
    flair: [sq_bundle_02_01, sq_bundle_02_02, sq_bundle_02_03, sq_bundle_02_04],
    related: sq_bundle_02_squiggle,
    slim: allSlim,
  },

  bundle3: {
    flair: [sq_bundle_03_01, sq_bundle_03_02, sq_bundle_03_03, sq_bundle_03_04],
    related: sq_bundle_03_squiggle,
    slim: allSlim,
  },

  bundle4: {
    flair: [sq_bundle_04_01, sq_bundle_04_02, sq_bundle_04_03, sq_bundle_04_04],
    related: sq_bundle_04_squiggle,
    slim: allSlim,
  },

  bundle5: {
    flair: [sq_bundle_05_01, sq_bundle_05_02, sq_bundle_05_03, sq_bundle_05_04],
    related: sq_bundle_05_squiggle,
    slim: allSlim,
  },

  bundle6: {
    flair: [sq_bundle_06_01, sq_bundle_06_02, sq_bundle_06_03, sq_bundle_06_04],
    related: sq_bundle_06_squiggle,
    slim: allSlim,
  },

  bundle7: {
    flair: [sq_bundle_07_01, sq_bundle_07_02, sq_bundle_07_03, sq_bundle_07_04],
    related: sq_bundle_07_squiggle,
    slim: allSlim,
  },

  bundle8: {
    flair: [sq_bundle_08_01, sq_bundle_08_02, sq_bundle_08_03, sq_bundle_08_04],
    related: sq_bundle_08_squiggle,
    slim: allSlim,
  },

  bundle9: {
    flair: [sq_bundle_09_01, sq_bundle_09_02, sq_bundle_09_03, sq_bundle_09_04],
    related: sq_bundle_09_squiggle,
    slim: allSlim,
  },

  bundle10: {
    flair: [sq_bundle_10_01, sq_bundle_10_02, sq_bundle_10_03, sq_bundle_10_04],
    related: sq_bundle_10_squiggle,
    slim: allSlim,
  },

  bundle11: {
    flair: [sq_bundle_11_01, sq_bundle_11_02, sq_bundle_11_03, sq_bundle_11_04],
    related: sq_bundle_11_squiggle,
    slim: allSlim,
  },

  bundle12: {
    flair: [sq_bundle_12_01, sq_bundle_12_02, sq_bundle_12_03, sq_bundle_12_04],
    related: sq_bundle_12_squiggle,
    slim: allSlim,
  },

  bundle13: {
    flair: [sq_bundle_13_01, sq_bundle_13_02, sq_bundle_13_03, sq_bundle_13_04],
    related: sq_bundle_13_squiggle,
    slim: allSlim,
  },

  bundle14: {
    flair: [sq_bundle_14_01, sq_bundle_14_02, sq_bundle_14_03, sq_bundle_14_04],
    related: sq_bundle_14_squiggle,
    slim: allSlim,
  },
}
