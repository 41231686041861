import {useStaticQuery, graphql} from 'gatsby'
import {CoreJourneysQuery} from 'gatsby-graphql-types-generated'
import compact from 'lodash/compact'

const useCoreJourneys = () => {
  const {contentfulCoreJourneys} = useStaticQuery<CoreJourneysQuery>(graphql`
    fragment CoreMilestone on ContentfulMilestone {
      id
      permalink
      articleCount
      heroRemoteUrl
      description {
        description
      }
      name
    }

    query CoreJourneys {
      contentfulCoreJourneys(slug: {eq: "core-journeys"}) {
        journeys {
          id
          name
          permalink
          icon
          shortDescription {
            shortDescription
          }
          milestones {
            ...CoreMilestone
            ...MilestoneCard
          }
        }
      }
    }
  `)

  return compact(contentfulCoreJourneys.journeys)
}

export default useCoreJourneys
