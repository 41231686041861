import HorizontalSquiggleSVG from 'assets/svgs/horizontal-squiggle.svg'
import shouldForwardProp from '@styled-system/should-forward-prop'
import {space, SpaceProps, compose} from 'styled-system'
import PurpleSquiggleSVG from 'assets/svgs/squiggle-purple.svg'
import GreenSquiggleSVG from 'assets/svgs/squiggle-green.svg'
import styled, {CSSObject} from '@emotion/styled'

import {Theme} from 'src/styles/theme'
import {useFlairContext} from 'src/context/FlairContext'

const hrStyles = compose(space)

export const SquiggleDivider = styled('hr', {
  shouldForwardProp,
})<SpaceProps>(
  {
    width: '100%',
    height: '20px',
    backgroundImage: `url(${HorizontalSquiggleSVG})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90% auto',
    border: 'none',
    maxWidth: '400px',
  },
  hrStyles
)

const squiggleCommonCss: CSSObject = {
  width: 240,
  height: 36,

  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',

  border: 'none',
} as const

export const GreenSquiggle = styled.hr({
  ...squiggleCommonCss,
  backgroundImage: `url(${GreenSquiggleSVG})`,
})

export const PurpleSquiggle = styled.hr({
  ...squiggleCommonCss,
  backgroundImage: `url(${PurpleSquiggleSVG})`,
})

export const RelatedSquiggle = () => {
  const bundle = useFlairContext()
  const related = bundle.related()
  const backgroundImage = related ? `url(${related})` : undefined

  return <hr css={{...squiggleCommonCss, backgroundImage}} />
}

interface DividerProps extends SpaceProps {
  color?: keyof Theme['colors']
  thin?: boolean
}

export const Divider = styled('hr', {shouldForwardProp})<DividerProps>(
  ({theme, color = 'grey2', thin = false}) => ({
    flexShrink: 0,
    backgroundColor: theme.colors[color],
    border: 'none',
    borderRadius: '2px',
    height: thin ? '2px' : '4px',
    margin: 0,
  }),
  hrStyles
)
