import {globalHistory} from '@reach/router'
import {navigate} from 'gatsby'

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete()
}

let isRequestInProgress = false

export const onPreRouteUpdate = async ({location}) => {
  if (isRequestInProgress) return

  if ('/captcha/' === location.pathname) return

  isRequestInProgress = true

  try {
    const response = await fetch('/v2/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.status === 429) {
      navigate('/captcha')
    }
  } catch (error) {
    console.error('Error sending request:', error)
  } finally {
    isRequestInProgress = false
  }
}

export {wrapRootElement, wrapPageElement} from './src/wrap-root-element'
