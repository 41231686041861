import {css} from '@emotion/react'

import WorkSansItalicWOFF from 'src/fonts/worksans-italic-webfont.woff'
import WorkSansItalicWOFF2 from 'src/fonts/worksans-italic-webfont.woff2'
import WorkSansMediumItalicWOFF from 'src/fonts/worksans-mediumitalic-webfont.woff'
import WorkSansMediumItalicWOFF2 from 'src/fonts/worksans-mediumitalic-webfont.woff2'
import WorkSansMediumWOFF from 'src/fonts/worksans-medium-webfont.woff'
import WorkSansMediumWOFF2 from 'src/fonts/worksans-medium-webfont.woff2'
import WorkSansRegularWOFF from 'src/fonts/worksans-regular-webfont.woff'
import WorkSansRegularWOFF2 from 'src/fonts/worksans-regular-webfont.woff2'
import WorkSansSemiBoldItalicWOFF from 'src/fonts/worksans-semibolditalic-webfont.woff'
import WorkSansSemiBoldItalicWOFF2 from 'src/fonts/worksans-semibolditalic-webfont.woff2'
import WorkSansSemiBoldWOFF from 'src/fonts/worksans-semibold-webfont.woff'
import WorkSansSemiBoldWOFF2 from 'src/fonts/worksans-semibold-webfont.woff2'
import {theme} from 'src/styles/theme'

const sharedFontFaceProperties = css`
  font-display: fallback;
`

export const fontFaceCss = css`
  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansRegularWOFF2}) format('woff2'),
      url(${WorkSansRegularWOFF}) format('woff');
    ${sharedFontFaceProperties}
    font-weight: ${theme.fontWeights.normal};
    font-style: normal;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansItalicWOFF2}) format('woff2'),
      url(${WorkSansItalicWOFF}) format('woff');
    ${sharedFontFaceProperties}
    font-weight: ${theme.fontWeights.normal};
    font-style: italic;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansMediumWOFF2}) format('woff2'),
      url(${WorkSansMediumWOFF}) format('woff');
    ${sharedFontFaceProperties}
    font-weight: ${theme.fontWeights.medium};
    font-style: normal;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansMediumItalicWOFF2}) format('woff2'),
      url(${WorkSansMediumItalicWOFF}) format('woff');
    ${sharedFontFaceProperties}
    font-weight: ${theme.fontWeights.medium};
    font-style: italic;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansSemiBoldWOFF2}) format('woff2'),
      url(${WorkSansSemiBoldWOFF}) format('woff');
    ${sharedFontFaceProperties}
    font-weight: ${theme.fontWeights.semiBold};
    font-style: normal;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansSemiBoldItalicWOFF2}) format('woff2'),
      url(${WorkSansSemiBoldItalicWOFF}) format('woff');
    ${sharedFontFaceProperties}
    font-weight: ${theme.fontWeights.semiBold};
    font-style: italic;
  }
`

export {
  WorkSansItalicWOFF,
  WorkSansItalicWOFF2,
  WorkSansMediumItalicWOFF,
  WorkSansMediumItalicWOFF2,
  WorkSansMediumWOFF,
  WorkSansMediumWOFF2,
  WorkSansRegularWOFF,
  WorkSansRegularWOFF2,
  WorkSansSemiBoldItalicWOFF,
  WorkSansSemiBoldItalicWOFF2,
  WorkSansSemiBoldWOFF,
  WorkSansSemiBoldWOFF2,
}
