import {CSSObject, css, SerializedStyles} from '@emotion/react'

import {theme} from './theme'

const borderWidth = 3

const gradientBorderCss: CSSObject = {
  position: 'relative',
  backgroundColor: theme.colors.white, // A background color is required, but can be overriden
  backgroundClip: 'padding-box',
  border: `solid ${borderWidth}px transparent`,
  transformStyle: 'preserve-3d',
  // transform: 'translateZ(1px)',
  zIndex: 1,

  '&:before': {
    content: `''`,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    // transform: 'translateZ(-1px)',
    margin: -1 * borderWidth,
    borderRadius: 'inherit',
    zIndex: -1,
  },

  '&:after': {
    content: `''`,
    position: 'absolute',
    top: '3px',
    right: '3px',
    bottom: '3px',
    left: '3px',
    // transform: 'translateZ(-1px)',
    margin: -1 * borderWidth,
    borderRadius: 'inherit',
    background: theme.colors.white,
    zIndex: -1,
  },
}

export const gradientBorder = (gradient: string): SerializedStyles =>
  css([
    gradientBorderCss,
    {
      '&:before': {
        backgroundImage: gradient,
      },
    },
  ])

export const simpleBorder = (width = borderWidth): CSSObject => ({
  border: `${width}px solid`,
  borderColor: theme.colors.grey2,
})

export const activeBorder: CSSObject = {
  border: '3px solid',
  borderColor: theme.colors.green,
}
