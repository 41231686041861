import styled from '@emotion/styled'
import {
  ColorProps,
  color,
  textAlign,
  TextAlignProps,
  fontSize,
  fontWeight,
  compose,
  space,
  SpaceProps,
  FontWeightProps,
  FontSizeProps,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

import AsProps from 'src/styles/AsProps'
import {typography} from 'src/styles'
import * as breakpoints from 'src/styles/mediaQuery'

interface TextProps
  extends AsProps,
    ColorProps,
    TextAlignProps,
    FontWeightProps,
    FontSizeProps,
    SpaceProps {
  truncate?: boolean
  hyphenate?: boolean
  breakWord?: boolean
  lines?: number
}

const truncate = ({truncate = false}: {truncate?: boolean}) =>
  truncate && typography.truncate

const hyphenate = ({hyphenate = false}: {hyphenate?: boolean}) =>
  hyphenate && typography.hyphenate

const breakWord = ({breakWord = false}: {breakWord?: boolean}) =>
  breakWord && typography.breakWord

const createLineClamp =
  (lineHeight: number) =>
  ({lines}: {lines?: number}) =>
    lines && typography.lineClamp(lines, lineHeight)

const textStyles = [
  compose(color, textAlign, fontWeight, fontSize, space),
  truncate,
  hyphenate,
  breakWord,
]

const defaultTextProps = {
  color: 'text',
}

export const Lede = styled('p', {shouldForwardProp})<TextProps>(
  typography.lede,
  textStyles
)
Lede.defaultProps = defaultTextProps

export const HeaderXl = styled('h1', {shouldForwardProp})<TextProps>(
  typography.header1,
  ({theme}) => ({
    [breakpoints.large]: {
      fontSize: theme.fontSizes.headerXl,
    },
  }),
  textStyles,
  createLineClamp(typography.header1.lineHeight)
)
HeaderXl.defaultProps = defaultTextProps

export const Header1 = styled('h1', {shouldForwardProp})<TextProps>(
  typography.header1,
  textStyles,
  createLineClamp(typography.header1.lineHeight)
)
Header1.defaultProps = defaultTextProps

export const Header2 = styled('h2', {shouldForwardProp})<TextProps>(
  typography.header2,
  textStyles,

  createLineClamp(typography.header2.lineHeight)
)
Header2.defaultProps = defaultTextProps

export const Header3 = styled('h3', {shouldForwardProp})<TextProps>(
  typography.header3,
  textStyles,
  createLineClamp(typography.header3.lineHeight)
)
Header3.defaultProps = defaultTextProps

export const Header4 = styled('h4', {shouldForwardProp})<TextProps>(
  typography.header4,
  textStyles,
  createLineClamp(typography.header4.lineHeight)
)
Header4.defaultProps = defaultTextProps

export const Body = styled('p', {shouldForwardProp})<TextProps>(
  typography.body,
  textStyles,
  createLineClamp(typography.body.lineHeight)
)
Body.defaultProps = defaultTextProps

export const SmallBody = styled('p', {shouldForwardProp})<TextProps>(
  typography.smallBody,
  textStyles,
  createLineClamp(typography.smallBody.lineHeight)
)
SmallBody.defaultProps = defaultTextProps

export const ExtraSmallBody = styled('p', {shouldForwardProp})<TextProps>(
  typography.extraSmallBody,
  textStyles,
  createLineClamp(typography.extraSmallBody.lineHeight)
)
ExtraSmallBody.defaultProps = defaultTextProps
