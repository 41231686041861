import {Global} from '@emotion/react'
import {ApolloProvider, InMemoryCache} from '@apollo/react-hooks'
import {ApolloClient} from '@apollo/client'
// import fetch from 'isomorphic-fetch'
import {HelmetProvider, Helmet} from 'react-helmet-async'

import {ThemeProvider} from 'src/context/ThemeProvider'
import {baseStyles} from 'src/styles/base'
import {ToastProvider} from 'src/context/ToastContext'
import {VerticalHeight} from 'src/VerticalHeight'
import {NavDrawerProvider} from 'src/context/NavDrawerContext'
import NavDrawer from 'src/components/NavDrawer'
import {ConfettiProvider} from 'src/context/ConfettiContext'
import {
  WorkSansItalicWOFF2,
  WorkSansMediumItalicWOFF2,
  WorkSansMediumWOFF2,
  WorkSansRegularWOFF2,
  WorkSansSemiBoldItalicWOFF2,
  WorkSansSemiBoldWOFF2,
} from 'src/styles/fonts'

const client = new ApolloClient({
  uri: `/v2/graphql`,
  credentials: 'include',
  cache: new InMemoryCache(),
})

export const wrapRootElement = ({element}: any) => (
  <ApolloProvider client={client}>
    <HelmetProvider>
      <ThemeProvider>
        <NavDrawerProvider>
          <ConfettiProvider>
            <ToastProvider>{element}</ToastProvider>
          </ConfettiProvider>
        </NavDrawerProvider>
      </ThemeProvider>
    </HelmetProvider>
  </ApolloProvider>
)

export const wrapPageElement = ({element}: any) => (
  <>
    <Helmet>
      <html data-js-loaded />
      <link
        rel="preload"
        as="font"
        href={WorkSansRegularWOFF2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={WorkSansItalicWOFF2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={WorkSansMediumItalicWOFF2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={WorkSansMediumWOFF2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={WorkSansSemiBoldItalicWOFF2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={WorkSansSemiBoldWOFF2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
    <Global styles={baseStyles} />
    <VerticalHeight />
    <NavDrawer />
    {element}
  </>
)
