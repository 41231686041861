import React from 'react'
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react'

import {theme} from 'src/styles/theme'

type ThemeProviderProps = {
  children?: React.ReactNode | React.ReactNode[]
}

export const ThemeProvider = ({children}: ThemeProviderProps) => {
  return (
    <EmotionThemeProvider theme={theme}>
      <>{children}</>
    </EmotionThemeProvider>
  )
}
