import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import {
  layout,
  background,
  flexbox,
  FlexboxProps,
  LayoutProps,
  BorderProps,
  BackgroundProps,
  BackgroundColorProps,
  color,
  compose,
  border,
  space,
  SpaceProps,
  GridProps,
  grid,
  position,
  PositionProps,
} from 'styled-system'

import AsProps from 'src/styles/AsProps'

export interface BoxProps
  extends AsProps,
    BackgroundProps,
    BackgroundColorProps,
    BorderProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    SpaceProps {}

interface FlexProps extends BoxProps {
  auto?: boolean
  children?: React.ReactNode | React.ReactNode[]
  transform?: string
}

export const boxStyles = compose(
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space
)

export const Box = styled('div', {
  shouldForwardProp,
})<BoxProps>(boxStyles)

export const Flex = styled('div', {
  shouldForwardProp,
})<BoxProps & FlexProps>(
  boxStyles,
  {display: 'flex'},
  ({auto = false}) => (auto ? 'flex: 1 1 auto' : undefined),
  ({transform}) => transform
)

export const Grid = styled('div', {
  shouldForwardProp,
})<FlexProps>({display: 'grid'}, boxStyles)

interface CenterProps {
  center?: boolean
  verticalCenter?: boolean
  horizontalCenter?: boolean
}

const center = (props: CenterProps) => {
  if (props.verticalCenter) {
    return {
      top: '50%',
      transform: 'translate(0, -50%)',
    }
  }

  if (props.horizontalCenter) {
    return {
      left: '50%',
      transform: 'translate(-50%, 0)',
    }
  }

  if (props.center) {
    return {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
  }
}

export interface PositionContainerProps extends BoxProps, CenterProps {}
export const positionContainerStyles = [boxStyles, center]
export const PositionContainer = styled('div', {
  shouldForwardProp,
})<PositionContainerProps>(positionContainerStyles)
