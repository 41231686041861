import {Cms_JourneyIcon} from 'gatsby-graphql-types-generated'

import {
  GetAJobIcon,
  GetToCollegeIcon,
  SucceedAtCollegeIcon,
  Props as IconProps,
} from 'src/components/icons'
import {JourneyIcon as JourneyIconEnum} from 'src/graphql-generated'

interface Props extends IconProps {
  icon: JourneyIconEnum | Cms_JourneyIcon
}

const ICON_MAP = {
  [JourneyIconEnum.Job]: GetAJobIcon,
  [JourneyIconEnum.GetCollege]: GetToCollegeIcon,
  [JourneyIconEnum.SuccessCollege]: SucceedAtCollegeIcon,
} as const

const JourneyIcon = ({icon, ...props}: Props) => {
  const IconComponent = ICON_MAP[icon]
  return <IconComponent {...props} />
}

export default JourneyIcon
