import random from 'lodash/random'
import Confetti from 'canvas-confetti'

import {Theme} from 'src/styles/theme'

const fireBigCannons = (confetti: typeof Confetti) => {
  // https://www.npmjs.com/package/canvas-confetti#options
  const leftCannonAngle = 45 // fires to the right
  const rightCannonAngle = 135 // fires to the left
  const spreadMin = 40
  const spreadMax = 55
  const particleCount = 100
  const startVelocity = 50
  const decay = 0.92
  const ticks = 250

  const randomSpread = () => random(spreadMin, spreadMax)

  const sharedOptions = {
    particleCount,
    startVelocity,
    decay,
    ticks,
  }

  confetti({
    ...sharedOptions,
    angle: leftCannonAngle,
    spread: randomSpread(),
    origin: {
      x: 0, // left
      y: 0.5, // middle
    },
  })

  confetti({
    ...sharedOptions,
    angle: rightCannonAngle,
    spread: randomSpread(),
    origin: {
      x: 1, // right
      y: 0.5, // middle
    },
  })

  confetti({
    ...sharedOptions,
    angle: leftCannonAngle,
    spread: randomSpread(),
    origin: {
      x: 0, // left
      y: 0.75, // bottom quarter
    },
  })

  confetti({
    ...sharedOptions,
    angle: rightCannonAngle,
    spread: randomSpread(),
    origin: {
      x: 1, // right
      y: 0.75, // bottom quarter
    },
  })
}

const fireSmallCannons = (confetti: typeof Confetti) => {
  const leftCannonAngle = 55 // fires to the right
  const rightCannonAngle = 125 // fires to the left
  const particleCount = 150

  const sharedOptions = {
    particleCount,
  }

  confetti({
    ...sharedOptions,
    angle: leftCannonAngle,
    origin: {
      x: 0, // left
      y: 0.75, // bottom quarter
    },
  })
  confetti({
    ...sharedOptions,
    angle: rightCannonAngle,
    origin: {
      x: 1, // right
      y: 0.75, // bottom quarter
    },
  })
}

const vibrateDevice = () => {
  if (window.navigator?.vibrate) {
    window.navigator.vibrate(200)
  }
}

export const fireConfetti = async ({
  theme,
  deviceVibration = false,
}: {
  theme: Theme
  deviceVibration?: boolean
}) => {
  await import('canvas-confetti').then(({default: confetti}) => {
    // Using a function so that the answer is recalculated in order to avoid
    // weirdness if the cannons ever need to be fired after the window size has
    // changed (eg. user visits page, resizes browser window, then performs action
    // that triggers confetti).
    const isDesktopViewport = () =>
      window.innerWidth >= parseInt(theme.breakpoints.md)

    if (isDesktopViewport()) {
      fireBigCannons(confetti)
    } else {
      fireSmallCannons(confetti)
    }

    if (deviceVibration) {
      vibrateDevice()
    }
  })
}
