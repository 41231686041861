import {CSSObject} from '@emotion/react'

export const containerShadow: CSSObject = {
  boxShadow: `0 1px 2px rgba(0,0,0,0.07),
              0 2px 4px rgba(0,0,0,0.07),
              0 4px 8px rgba(0,0,0,0.07),
              0 8px 16px rgba(0,0,0,0.07),
              0 16px 32px rgba(0,0,0,0.07),
              0 32px 64px rgba(0,0,0,0.07)`,
} as const

// Increase shadow size by 30% and darken slightly.
export const containerHoverShadow: CSSObject = {
  boxShadow: `0 1.3px 2.6px rgba(0,0,0,0.1),
              0 2.6px 5.2px rgba(0,0,0,0.1),
              0 5.2px 10.4px rgba(0,0,0,0.1),
              0 10.4px 20.8px rgba(0,0,0,0.1),
              0 20.8px 41.6px rgba(0,0,0,0.1),
              0 41.6px 83.2px rgba(0,0,0,0.1)`,
} as const

export const drawerShadow: CSSObject = {
  boxShadow: '0px 0px 40px rgba(2, 21, 70, 0.2)',
} as const

export const desktopNavShadow: CSSObject = {
  boxShadow: '0px 20px 40px -20px rgba(2,21,70,0.2)',
} as const

export const homeHeroCardShadow: CSSObject = {
  boxShadow: `0px 4px 30px rgba(20, 36, 44, 0.2),
    0px 2px 10px rgba(20, 36, 44, 0.16),
    0px 4px 4px rgba(20, 36, 44, 0.25)`,
} as const

export const noShadow: CSSObject = {
  boxShadow: 'none',
} as const
