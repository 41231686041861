export const zIndexes = {
  toast: 300,

  modal: 200,

  footer: 100,
  header: 100,
  drawer: 90,

  base: 0,
} as const
