import {
  deterministicRandom,
  sample,
  createExhaustiveSampler,
} from 'src/lib/stats'

import {bundles} from './flairSvg'

export const BUNDLE_SETS = bundles

export type BundleName = keyof typeof BUNDLE_SETS

export const createFlairSampler = (seed: number, bundleName?: BundleName) => {
  const flairBundle = bundleName
    ? BUNDLE_SETS[bundleName]
    : sample(Object.values(BUNDLE_SETS), deterministicRandom(seed))

  return {
    flair: createExhaustiveSampler(flairBundle.flair, seed),
    slim: createExhaustiveSampler(flairBundle.slim, seed),
    related: () => flairBundle.related,
  }
}
