import {createContext, useMemo, useContext} from 'react'
import debounce from 'lodash/debounce'

import {theme} from 'src/styles'
import {fireConfetti} from 'src/lib/confetti'

interface ConfettiActions {
  fireConfetti: (vibrateDevice?: boolean) => void
}
export const ConfettiContext = createContext<ConfettiActions>({
  fireConfetti: () => {
    throw new Error('Missing Confetti provider')
  },
})

const debouncedFireConfetti = debounce(
  (vibrateDevice?: boolean) =>
    fireConfetti({theme, deviceVibration: vibrateDevice}),
  20
)

export const ConfettiProvider = ({children}: {children?: React.ReactNode}) => {
  const actions = useMemo(
    () => ({
      fireConfetti: (vibrateDevice = false) => {
        debouncedFireConfetti(vibrateDevice)
      },
    }),
    []
  )

  return (
    <ConfettiContext.Provider value={actions}>
      {children}
    </ConfettiContext.Provider>
  )
}

export const useConfettiCannon = () => useContext(ConfettiContext)
