import {CSSObject} from '@emotion/react'

import {theme} from './theme'
import {cssCalc} from '.'

const commonStyles = {
  fontFeatureSettings: `"kern", "ss01"`,
} as const

export const headerXl = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.headerXl,
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: '-0.01rem',
  lineHeight: 1.1,
} as const

export const header1 = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.header1,
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: '-0.01rem',
  lineHeight: 1.1,
} as const

export const header2 = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.header2,
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: '-0.01rem',
  lineHeight: 1.2,
} as const

export const header3 = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.header3,
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: '-0.01rem',
  lineHeight: 1.1,
} as const

export const header4 = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.header4,
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: '-0.01rem',
  lineHeight: 1.2,
} as const

export const lede = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.lede,
  fontWeight: theme.fontWeights.medium,
  letterSpacing: '-0.01rem',
  lineHeight: 1.4,
} as const

export const body = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.body,
  fontWeight: theme.fontWeights.normal,
  letterSpacing: '-0.01rem',
  lineHeight: 1.5,
} as const

export const smallBody = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.normal,
  letterSpacing: '-0.01rem',
  lineHeight: 1.5,
} as const

export const extraSmallBody = {
  ...commonStyles,
  fontFamily: theme.fonts.sans,
  fontSize: theme.fontSizes.extraSmall,
  fontWeight: theme.fontWeights.normal,
  letterSpacing: '-0.01rem',
  lineHeight: 1.5,
} as const

export const truncate: CSSObject = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}

export const breakWord: CSSObject = {
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
}

// https://justmarkup.com/articles/2015-07-31-dealing-with-long-words-in-css/#final-solution
export const hyphenate: CSSObject = {
  ...breakWord,
  hyphens: 'auto',
}

export const lineClamp = (lines: number, lineHeight = 1.5): CSSObject => ({
  maxHeight: cssCalc(lines, '*', `${lineHeight}em`),
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lines,
  overflow: 'hidden',
})
