import {forwardRef, ReactNode} from 'react'
import styled from '@emotion/styled'

import {
  vhHeight,
  zIndexes,
  constants,
  cssCalc,
  shadow,
  animation,
} from 'src/styles'

interface Props {
  isOpen: boolean
  anchor?: 'right'
  children: ReactNode
}

const Container = styled.div<Props>(
  (props) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: cssCalc(constants.MOBILE_HEADER_HEIGHT, '+ 1px'),
    overflow: 'auto',
    width: '100vw',
    maxWidth: '300px',
    height: cssCalc(vhHeight(100), '-', constants.MOBILE_HEADER_HEIGHT),
    background: props.theme.colors.white,
    zIndex: zIndexes.drawer,
    transition: [
      animation.drawerClose('transform'),
      `visibility 0s ${animation.durations.drawerClose}`,
    ].join(', '),
    transform: 'translateX(100%) translateZ(1px)',
    right: 0,
    left: 'auto',
    visibility: 'hidden',
    ...shadow.drawerShadow,
  }),
  ({isOpen}) =>
    isOpen && {
      visibility: 'visible',
      transition: animation.drawerOpen('transform'),
      transform: 'translateX(0) translateZ(1px)',
    }
)
const Drawer = forwardRef<HTMLDivElement, Props>(
  ({children, isOpen, anchor = 'right', ...props}, ref) => (
    <Container ref={ref} anchor={anchor} isOpen={isOpen} {...props}>
      {children}
    </Container>
  )
)

export default Drawer
