import {createContext, useState, useContext, useMemo} from 'react'

export interface ToastMessage {
  body: {
    text: string
    emoji?: {emoji: string; label: string}
    heading?: string
  }
  key: number
  clear: () => void
}

// NOTE: keys needed when messages are rendered
let key = 0

const ToastContext = createContext<ToastMessage[]>([])

interface ToastActions {
  addToast: (message: Pick<ToastMessage, 'body'>) => void
}

const ToastActionContext = createContext<ToastActions>({addToast: () => {}})

export const ToastProvider = ({children}: {children?: React.ReactNode}) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const clearMessage = (key: number) => {
    setMessages((messages) =>
      messages.filter((otherMessage) => otherMessage.key !== key)
    )
  }

  const actions = useMemo(
    (): ToastActions => ({
      addToast: (message) => {
        const messageKey = key++

        const timeoutId = setTimeout(() => {
          clearMessage(messageKey)
        }, 4000)

        setMessages((messages) => [
          ...messages,
          {
            ...message,
            key: messageKey,
            clear: () => {
              clearTimeout(timeoutId)
              clearMessage(messageKey)
            },
          },
        ])
      },
    }),
    [setMessages]
  )
  // NOTE: State and Dispatch providers are split so that new objects are not created exessively
  // https://kentcdodds.com/blog/how-to-optimize-your-context-value
  return (
    <ToastContext.Provider value={messages}>
      <ToastActionContext.Provider value={actions}>
        {children}
      </ToastActionContext.Provider>
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
export const useToastAction = () => useContext(ToastActionContext)
