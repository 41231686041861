// https://material.io/design/motion/speed.html#duration

export const durations = {
  selection: '100ms',
  fadeIn: '150ms',
  fadeOut: '75ms',
  drawerOpen: '250ms',
  drawerClose: '200ms',
  hoverEnter: '250ms',
  hoverLeave: '200ms',
}

export const easing = {
  standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)',
}

export const fadeIn = (prop: string) =>
  [prop, durations.fadeIn, easing.standard].join(' ')

export const fadeOut = (prop: string) =>
  [prop, durations.fadeOut, easing.standard].join(' ')

export const selection = (prop: string) =>
  [prop, durations.selection, easing.standard].join(' ')

export const drawerOpen = (prop: string) =>
  [prop, durations.drawerOpen, easing.standard].join(' ')

export const drawerClose = (prop: string) =>
  [prop, durations.drawerClose, easing.standard].join(' ')

export const hoverEnter = (prop: string) =>
  [prop, durations.hoverEnter, easing.standard].join(' ')

export const hoverLeave = (prop: string) =>
  [prop, durations.hoverLeave, easing.standard].join(' ')
