import {css} from '@emotion/react'

import {createReset} from 'src/styles/reset'
import {fontFaceCss} from 'src/styles/fonts'
import * as typography from 'src/styles/typography'
import * as mediaQuery from 'src/styles/mediaQuery'
import {theme} from 'src/styles/theme'

export const baseStyles = css`
  ${fontFaceCss}
  ${createReset(css(typography.body))}
  html {
    font-size: 100%;
    overflow-x: hidden;
    color: ${theme.colors.text};
    ${mediaQuery.small} {
      font-size: 120%;
    }
  }
  strong,
  b {
    font-weight: ${theme.fontWeights.semiBold};
  }
  *:visited {
    color: ${theme.colors.visitedLink};
    text-decoration-color: ${theme.colors.visitedLink};
  }
  a {
    text-decoration-color: currentColor;
  }
`
