export const deterministicRandom = (initialSeed: number = 0x2f6e2b1) => {
  let seed = initialSeed
  return () => {
    // Robert Jenkins’ 32 bit integer hash function
    seed = (seed + 0x7ed55d16 + (seed << 12)) & 0xffffffff
    seed = (seed ^ 0xc761c23c ^ (seed >>> 19)) & 0xffffffff
    seed = (seed + 0x165667b1 + (seed << 5)) & 0xffffffff
    seed = ((seed + 0xd3a2646c) ^ (seed << 9)) & 0xffffffff
    seed = (seed + 0xfd7046c5 + (seed << 3)) & 0xffffffff
    seed = (seed ^ 0xb55a4f09 ^ (seed >>> 16)) & 0xffffffff
    return (seed & 0xfffffff) / 0x10000000
  }
}

export const sample = <T>(
  collection: readonly T[],
  randomFn: () => number = Math.random
) => collection[Math.floor(randomFn() * collection.length)]

export const shuffle = <T>(
  collection: readonly T[],
  randomFn: () => number = Math.random
) => {
  const shuffled = [...collection]

  shuffled.forEach((i, index) => {
    const randomIndex = Math.floor(randomFn() * index)
    shuffled[index] = shuffled[randomIndex]
    shuffled[randomIndex] = i
  })

  return shuffled
}

export const createExhaustiveSampler = <T>(
  items: readonly T[],
  seed?: number
) => {
  const randomFn = seed ? deterministicRandom(seed) : Math.random
  const shuffledSet = shuffle(items, randomFn)
  let callCount = 0
  return () =>
    callCount < shuffledSet.length
      ? shuffledSet[callCount++]
      : sample(shuffledSet, randomFn)
}
