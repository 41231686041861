import queryString from 'query-string'

export const localPaths = {
  home: '/',
  dashboard: '/dashboard/',
  sendText: '/text-us/',
  scholarships: '/scholarships',

  chooseAnEmployer: '/journey/find-a-job/choosing-an-employer/',
  writeAResume: '/journey/find-a-job/writing-a-resume/',
  jobApplicationHelp: '/journey/find-a-job/job-application-help/',
  prepareForInterview: '/journey/find-a-job/job-interviewing/',
  buildJobSkills:
    '/journey/detroit/success-on-the-job/career-communication-badge-how-to-talk-to-your-boss-and-co-workers/',

  interviewCoach: '/interview-coach/',
  journeys: {
    getToCollege: '/journey/get-to-college/',
    succeedInCollege: '/journey/succeed-in-college/',
    findAJob: '/journey/find-a-job/',
    detroit: '/journey/detroit/',
    csudh: '/journey/csudh/',
    weBelongInCollege: '/journey/webelongincollege/',
    scholarships: '/scholarships/',
  },

  resumeHelp: '/resume-help/',
  collegeAppHelp: '/college-review/',

  jobSearch: '/job-search/',

  login: '/log-in/',
  signUp: '/sign-up/',
  resetPassword: '/reset-password/',

  profile: '/profile/',

  schoolChallenges: '/educators/school-challenges/',
  educatorResources: '/educators/',
  resourceHub: '/resource-hub/',
  partner: '/partner-with-us',
  educatorJoinCommunity: '/educators/join-our-community/',
  educatorContent: '/partner-content-database/',
  contactUs: '/contact-us/',
  ourTeam: '/about-us/our-team/',
  ourApproach: '/about-us/our-approach/',
  boardOfDirectors: '/about-us/board-of-directors/',

  helpApplying: '/i-need-help-applying-for-a-job/',
  appliedNowWhat: '/i-applied-for-a-job-now-what/',

  jobSearchResults: (params: Record<string, string | undefined>) =>
    `/job-search/results?${queryString.stringify(params)}`,

  interviewcoach: '/interviewcoach',

  termsAndConditions: '/terms/',
  privacyPolicy: '/privacy/',
  aboutUs: '/about-us/',
  faq: '/faqs/',
  covid19:
    '/journey/get-to-college/help-during-covid-19/?utm_source=website&utm_medium=Home%20Page%20Button&utm_campaign=Help%20During%20COVID',
} as const

export const externalPaths = {
  facebook: 'https://www.facebook.com/GetSchooled/',
  instagram: 'https://www.instagram.com/getschooled/',
  twitter: 'https://twitter.com/getschooled',
  tiktok: 'https://www.tiktok.com/@getschooled?traffic_type=getschooled',
  youtube:
    'https://www.youtube.com/user/getschooledfdn?utm_source=website&utm_medium=Home%20Page%20Social%20Icon&utm_campaign=Social%20Icon',
  youtubeTerms: 'https://www.youtube.com/t/terms',
}
