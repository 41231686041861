import {Student} from 'src/types'

import {request, Method, Params} from './request'

export interface FieldErrors {
  errors: Record<string, string[]>
}

function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const apiUrl = (path: string) => `/v2${path}`
const apiRequest = <T>(method: Method, path: string, params: Params = {}) =>
  request<T>(method, apiUrl(path), params, {
    credentials: 'include',
    headers: {'X-CSRF-Token': getCookie('CSRF-TOKEN')},
  })

export type SignUpParams = {
  student: {
    email: string
    password: string
    birthdate: string
    agree_terms: boolean
    graduation_year: number
    state: string
    sign_up_from: string
  }
}

export const signUp = (params: SignUpParams) =>
  apiRequest<{student: Student} | FieldErrors>('post', '/students', params)

export type SignInParams = {
  student: {
    email: string
    password: string
  }
}

export const signIn = (params: SignInParams) =>
  apiRequest<{error: string} | {student: Student}>(
    'post',
    '/students/sign_in',
    params
  )

export const signOut = () => apiRequest<void>('delete', '/students/sign_out')

export const resetPassword = (params: {student: {email: string}}) =>
  apiRequest<FieldErrors | {}>('post', '/students/password', params)

export const setNewPassword = (params: {
  student: {
    password: string
    password_confirmation: string
    reset_password_token: string
  }
}) => apiRequest<FieldErrors | void>('put', '/students/password', params)
