import {Link} from 'gatsby'
import styled from '@emotion/styled'

import {typography} from 'src/styles'

export const Action = styled.button({
  padding: 0,
  margin: 0,
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'default',
  },
})

export const ActionLink = styled(Link)(({theme}) => ({
  ...typography.body,
  padding: 0,
  margin: 0,
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.colors.text,
  display: 'block',
  '&:visited': {
    color: 'currentColor',
  },
  '&[aria-current="page"]': {
    color: theme.colors.grey5,
  },
}))
