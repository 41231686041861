import {useViewerQuery} from 'src/graphql-generated'
import {signOut as requestSignOut} from 'src/gs-api'

const useViewer = () => {
  const {loading, data, refetch} = useViewerQuery()

  const signOut = () =>
    requestSignOut().then(() => {
      refetch()
    })

  if (loading) {
    return {viewer: null, refetch, loading: true, signOut} as const
  }

  const viewer =
    !data || !data.viewer ? ('not-logged-in' as const) : data.viewer

  if (viewer === 'not-logged-in') {
    return {viewer, loggedIn: false, signOut, loading: false, refetch} as const
  }

  return {viewer, refetch, loading, signOut, loggedIn: true} as const
}

export default useViewer
