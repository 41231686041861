const colors = {
  blue: '#004EDF',
  electricBlue: '#0025DD',
  lakeBlue: '#00BFDF',
  mint: '#78EEC4',
  orange: '#FDA900',
  pink: '#FE04C9',
  purple: '#EA04FE',
  red: '#FF3F00',
  yellow: '#FDE400',
  black: '#021546',
} as const

const base = {
  purpleRed: `linear-gradient(to right, ${colors.purple}, ${colors.red})`,
  pinkYellow: `linear-gradient(to right, ${colors.pink}, ${colors.yellow})`,
  mintLakeBlue: `linear-gradient(117.81deg, ${colors.mint} 9.22%, ${colors.lakeBlue} 84.63%)`,
  mintLakeBlue2: `linear-gradient(108.06deg, #78EEC4 9.22%, #00BFDF 84.63%)`,
  bluePurple: `linear-gradient(to right, #35C0DC, #4A50E9)`,
  greenYellow: 'linear-gradient(to right, #00DF91, #FFF618)',
  blueMint: `linear-gradient(270.52deg, #4AFFC5 0%, #09BD9E 100%)`,
  blueJava: 'linear-gradient(270deg, #12B3D8 9.99%, #3666AE 85.03%)',
} as const

const gradientAliases = {
  toggleControl: base.mintLakeBlue,
  primaryButton: base.purpleRed,
  toast: base.mintLakeBlue,
} as const

export const gradients = {
  ...base,
  ...gradientAliases,
} as const
